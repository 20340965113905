<template>
<!-- Deprecated, do not use.  Use Inventory Search instead --> 
  <v-dialog
    v-model="dialog"
    width="80%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @input="close"
    style="z-index: 1000"
  >
    <v-card>
      <v-card-title>Inventory Lookup</v-card-title>
      <v-card-text>
        <div class="d-flex flex-column flex-md-row justify-center">
          <v-text-field
            label="Inventory ID"
            placeholder="Press enter or search to lookup inventory"
            clearable
            v-model="inventoryId"
            @keypress.enter="search"
            @click:clear="clearSearch"
          ></v-text-field>
          <v-btn class="ml-0 ml-md-5 mt-3" @click="search">Search</v-btn>
        </div>
        <v-sheet v-if="inventory" class="my-5 pa-2" :elevation="5">
          <div class="text-h6 mx-4">Found Inventory</div>
          <v-simple-table class="pa-4 table">
            <tr>
              <td>ID</td>
              <td>{{ inventory.id }}</td>
            </tr>
            <tr>
              <td>Product</td>
              <td>{{ inventory.productName }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ inventory.status }}</td>
            </tr>
            <tr v-for="field in inventory.fields" :key="field.id">
              <td>{{ field.field }}</td>
              <td>
                <span v-if="field.type == 'Number'">{{
                  field.fieldValueNumber | formatNumber
                }}</span>
                <span v-else-if="field.type == 'Date'">{{
                  field.fieldValueDate | formatDateYear
                }}</span>
                <span v-else>{{ field.fieldValueString }}</span>
              </td>
            </tr>
            <tr>
              <td>Stock Time</td>
              <td>{{ inventory.stockTime | formatDateYear }}</td>
            </tr>
            <tr>
              <td>Quantity</td>
              <td>{{ inventory.quantity | formatNumber }}</td>
            </tr>
            <tr>
              <td>Unit</td>
              <td>
                <inventory-unit-details v-model="inventory.id" :count="inventory.unit" :key="inventory.id"/>
              </td>
            </tr>
            <tr>
              <td>CoA</td>
              <td >
                <v-menu v-if="inventory.coa && inventory.coa.length > 0" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" text color="primary">
                      <v-icon>mdi-certificate</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    class="pa-5"
                    v-for="coa in inventory.coa"
                    :key="coa.id"
                  >
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> Test Time </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ coa.testTime | formatDateYear }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> Technician </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ coa.technician }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> Sample Name </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ coa.sampleName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> Program </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ coa.program }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title> Quality </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ coa.quality }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div v-else>N/A</div>
              </td>
            </tr>
          </v-simple-table>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mb-5"
          @click="submit"
          :disabled="!inventory"
          color="primary"
        >
          Submit
        </v-btn>
        <v-btn
          class="mb-5"
          @click="
            dialog = false;
            close();
          "
        >
          Cancel
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
const InventoryUnitDetails = () => import("@/components/inventory/InventoryUnitDetails.vue");

export default {
  components: { InventoryUnitDetails },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    activated: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  watch: {
    activated(newValue) {
      if (newValue) {
        if (this.value) {
          this.inventoryId = this.value;
          this.search();
        } else {
          this.inventory = null;
          this.inventoryId = null;
        }
        this.dialog = true;
      }
    },
  },
  data: () => {
    return {
      dialog: false,
      inventoryId: null,
      inventory: null,
    };
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    search() {
      if (this.inventoryId) {
        this.$axios
          .get("/inventory/" + this.inventoryId)
          .then((response) => {
            this.inventory = response.data;
          })
          .catch(() => {
            this.inventory = null;
            this.inventoryId = null;
            this.addErrorMessage(
              "No inventory found with inventory ID " + this.inventoryId
            );
          });
      } else {
        this.inventory = null;
      }
    },
    clearSearch() {
      this.inventoryId = null;
      this.inventory = null;
    },
    close() {
      this.$emit("input", null);
      this.$emit("update:activated", false);
    },
    submit() {
      this.dialog = false;
      this.$emit("input", this.inventory.id);
      this.$emit("selected", this.inventory);
      this.$emit("update:activated", false);
    },
  },
};
</script>

<style></style>
